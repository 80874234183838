:root {
  --_134tqwz0: 36px;
  --_134tqwz1: 32px;
  --_134tqwz2: 24px;
  --_134tqwz3: 20px;
  --_134tqwz4: 18px;
  --_134tqwz5: 16px;
  --_134tqwz6: 14px;
  --_134tqwz7: 20px;
  --_134tqwz8: 17px;
  --_134tqwz9: 15px;
  --_134tqwza: 13px;
  --_134tqwzb: 12px;
  --_134tqwzc: 10px;
  --_134tqwzd: 14px;
  --_134tqwze: 16px;
  --_134tqwzf: 44px;
  --_134tqwzg: 36px;
  --_134tqwzh: 30px;
  --_134tqwzi: 24px;
  --_134tqwzj: 22px;
  --_134tqwzk: 20px;
  --_134tqwzl: 18px;
  --_134tqwzm: 30px;
  --_134tqwzn: 24px;
  --_134tqwzo: 22px;
  --_134tqwzp: 20px;
  --_134tqwzq: 18px;
  --_134tqwzr: 16px;
  --_134tqwzs: 20px;
  --_134tqwzt: 4px;
  --_134tqwzu: 8px;
  --_134tqwzv: 12px;
  --_134tqwzw: 16px;
  --_134tqwzx: 32px;
  --_134tqwzy: 2px;
  --_134tqwzz: 50%;
  --_134tqwz10: 20px;
  --_134tqwz11: 0px;
  --_134tqwz12: 1px;
  --_134tqwz13: 2px;
  --_134tqwz14: 3px;
  --_134tqwz15: 1.5px;
  --_134tqwz16: 1.2px;
  --_134tqwz17: 1px;
  --_134tqwz18: 0.5px;
  --_134tqwz19: 0.2px;
  --_134tqwz1a: rgba(0,0,0,0.025);
  --_134tqwz1b: rgba(0,0,0,0.05);
  --_134tqwz1c: rgba(0,0,0,0.1);
  --_134tqwz1d: rgba(0,0,0,0.2);
  --_134tqwz1e: rgba(0,0,0,0.3);
  --_134tqwz1f: rgba(0,0,0,0.4);
  --_134tqwz1g: rgba(0,0,0,0.5);
  --_134tqwz1h: rgba(0,0,0,0.6);
  --_134tqwz1i: rgba(0,0,0,0.7);
  --_134tqwz1j: rgba(0,0,0,0.8);
  --_134tqwz1k: rgba(0,0,0,0.9);
  --_134tqwz1l: rgba(255,255,255,0.25);
  --_134tqwz1m: rgba(255,255,255,0.05);
  --_134tqwz1n: rgba(255,255,255,0.1);
  --_134tqwz1o: rgba(255,255,255,0.2);
  --_134tqwz1p: rgba(255,255,255,0.3);
  --_134tqwz1q: rgba(255,255,255,0.4);
  --_134tqwz1r: rgba(255,255,255,0.5);
  --_134tqwz1s: rgba(255,255,255,0.6);
  --_134tqwz1t: rgba(255,255,255,0.7);
  --_134tqwz1u: rgba(255,255,255,0.8);
  --_134tqwz1v: rgba(255,255,255,0.9);
  --_134tqwz1w: rgba(33,31,53,0.24);
  --_134tqwz1x: rgba(248,248,250,0.04);
  --_134tqwz1y: rgba(248,248,250,0.16);
  --_134tqwz1z: rgba(0,0,0,0.04);
  --_134tqwz20: rgba(0,0,0,0.05);
  --_134tqwz21: rgba(0,0,0,0.08);
  --_134tqwz22: rgba(0,0,0,0.1);
  --_134tqwz23: rgba(0,0,0,0.16);
  --_134tqwz24: rgba(0,0,0,0.2);
  --_134tqwz25: rgba(0,0,0,0.24);
  --_134tqwz26: rgba(0,0,0,0.3);
  --_134tqwz27: rgba(0,0,0,0.32);
  --_134tqwz28: rgba(0,0,0,0.4);
  --_134tqwz29: rgba(255,255,255,0.02);
  --_134tqwz2a: rgba(255,255,255,0.03);
  --_134tqwz2b: rgba(255,255,255,0.04);
  --_134tqwz2c: rgba(255,255,255,0.07);
  --_134tqwz2d: rgba(255,255,255,0.08);
  --_134tqwz2e: rgba(255,255,255,0.12);
  --_134tqwz2f: rgba(255,255,255,0.24);
  --_134tqwz2g: rgba(255,255,255,0.8);
  --_134tqwz2h: #e8e8ea;
  --_134tqwz2i: #d2d2d6;
  --_134tqwz2j: #bcbbc2;
  --_134tqwz2k: #a6a5ae;
  --_134tqwz2l: #908f9a;
  --_134tqwz2m: #797885;
  --_134tqwz2n: #636271;
  --_134tqwz2o: #4d4b5d;
  --_134tqwz2p: #373549;
  --_134tqwz2q: #171629;
  --_134tqwz2r: #000000;
  --_134tqwz2s: #FFFFFF;
  --_134tqwz2t: #2DB2E0;
  --_134tqwz2u: #5AD4FF;
  --_134tqwz2v: #4FB1F9;
  --_134tqwz2w: #5B9EFF;
  --_134tqwz2x: #63C5FF;
  --_134tqwz2y: #F8F8FA;
  --_134tqwz2z: #E8E8EA;
  --_134tqwz30: #D1D1D5;
  --_134tqwz31: #B3B2B8;
  --_134tqwz32: #95949B;
  --_134tqwz33: #77767E;
  --_134tqwz34: #595861;
  --_134tqwz35: #3C3B44;
  --_134tqwz36: #1E1D26;
  --_134tqwz37: #2A292E;
  --_134tqwz38: #A35200;
  --_134tqwz39: #206444;
  --_134tqwz3a: #EBFFFC;
  --_134tqwz3b: #C9FFF1;
  --_134tqwz3c: #ADFFE2;
  --_134tqwz3d: #94FFCF;
  --_134tqwz3e: #81FFBB;
  --_134tqwz3f: #72F2A6;
  --_134tqwz3g: #62D89A;
  --_134tqwz3h: #52BD8D;
  --_134tqwz3i: #736285;
  --_134tqwz3j: #605172;
  --_134tqwz3k: #544868;
  --_134tqwz3l: #4A405E;
  --_134tqwz3m: #3F3754;
  --_134tqwz3n: #353547;
  --_134tqwz3o: #352F4A;
  --_134tqwz3p: #302F4A;
  --_134tqwz3q: #2D2D40;
  --_134tqwz3r: #2B2740;
  --_134tqwz3s: #211F35;
  --_134tqwz3t: #1C1B2E;
  --_134tqwz3u: #171629;
  --_134tqwz3v: #21212E;
  --_134tqwz3w: #FDF5FF;
  --_134tqwz3x: #F3D8FF;
  --_134tqwz3y: #E5BDFC;
  --_134tqwz3z: #D3A5F7;
  --_134tqwz40: #C08FEE;
  --_134tqwz41: #AB7DE3;
  --_134tqwz42: #966DD5;
  --_134tqwz43: #7A5AB9;
  --_134tqwz44: #60479C;
  --_134tqwz45: #FFEDEB;
  --_134tqwz46: #FFA8AE;
  --_134tqwz47: #FF8395;
  --_134tqwz48: #F74B75;
  --_134tqwz49: #E7366C;
  --_134tqwz4a: #CB2863;
  --_134tqwz4b: #AC1D58;
  --_134tqwz4c: #8C134C;
  --_134tqwz4d: #780038;
  --_134tqwz4e: #A61807;
  --_134tqwz4f: #FFFFD5;
  --_134tqwz4g: #FFFF92;
  --_134tqwz4h: #FFF076;
  --_134tqwz4i: #F9E280;
  --_134tqwz4j: #F7DB60;
  --_134tqwz4k: #E8C34D;
  --_134tqwz4l: #CBA13D;
  --_134tqwz4m: #94833A;
  --_134tqwz4n: #AC802E;
  --_134tqwz4o: #8B6121;
  --_134tqwz4p: #4B4B66;
  --_134tqwz4q: #44445C;
  --_134tqwz4r: #353548;
  --_134tqwz4s: #343445;
  --_134tqwz4t: #282939;
  --_134tqwz4u: #1b1c29;
  --_134tqwz4v: #50FAAB;
  --_134tqwz4w: #206444;
  --_134tqwz4x: #CB2863;
  --_134tqwz4y: rgba(33,31,53,0.5);
  --_134tqwz4z: #9797B7;
  --_134tqwz50: #505066;
  --_134tqwz51: #E1F0E1;
  --_134tqwz52: #082415;
  --_134tqwz53: #50FAAB;
  --_134tqwz54: rgba(114,242,166,0.2);
  --_134tqwz55: #FF63EF;
  --_134tqwz56: #44435e;
  --_134tqwz57: #2F2E47;
  --_134tqwz58: #26253C;
  --_134tqwz59: #33274D;
  --_134tqwz5a: #302f4a;
  --_134tqwz5b: #FAF0E6;
  --_134tqwz5c: 0px;
  --_134tqwz5d: 8px;
  --_134tqwz5e: 16px;
  --_134tqwz5f: 24px;
  --_134tqwz5g: 32px;
  --_134tqwz5h: 40px;
  --_134tqwz5i: 48px;
  --_134tqwz5j: 56px;
  --_134tqwz5k: 64px;
  --_134tqwz5l: 72px;
  --_134tqwz5m: 80px;
  --_134tqwz5n: 88px;
  --_134tqwz5o: 96px;
  --_134tqwz5p: 104px;
  --_134tqwz5q: 112px;
  --_134tqwz5r: 120px;
  --_134tqwz5s: 128px;
  --_134tqwz5t: 136px;
  --_134tqwz5u: 144px;
  --_134tqwz5v: 152px;
  --_134tqwz5w: 160px;
  --_134tqwz5x: 168px;
  --_134tqwz5y: 176px;
  --_134tqwz5z: 184px;
  --_134tqwz60: 192px;
  --_134tqwz61: 200px;
  --_134tqwz62: 208px;
  --_134tqwz63: 216px;
  --_134tqwz64: 224px;
  --_134tqwz65: 232px;
  --_134tqwz66: 240px;
  --_134tqwz67: 248px;
  --_134tqwz68: 256px;
  --_134tqwz69: 264px;
  --_134tqwz6a: 272px;
  --_134tqwz6b: 4px;
  --_134tqwz6c: 12px;
  --_134tqwz6d: 20px;
  --_134tqwz6e: 28px;
  --_134tqwz6f: 36px;
  --_134tqwz6g: 44px;
  --_134tqwz6h: 52px;
  --_134tqwz6i: 60px;
  --_134tqwz6j: 68px;
  --_134tqwz6k: 76px;
  --_134tqwz6l: 84px;
  --_134tqwz6m: 92px;
  --_134tqwz6n: 100px;
  --_134tqwz6o: 108px;
  --_134tqwz6p: 116px;
  --_134tqwz6q: 124px;
  --_134tqwz6r: 132px;
  --_134tqwz6s: 140px;
  --_134tqwz6t: 148px;
  --_134tqwz6u: 156px;
  --_134tqwz6v: 164px;
  --_134tqwz6w: 172px;
  --_134tqwz6x: 180px;
  --_134tqwz6y: 188px;
  --_134tqwz6z: 196px;
  --_134tqwz70: 204px;
  --_134tqwz71: 212px;
  --_134tqwz72: 220px;
  --_134tqwz73: 228px;
  --_134tqwz74: 236px;
  --_134tqwz75: 244px;
  --_134tqwz76: 252px;
  --_134tqwz77: 260px;
  --_134tqwz78: 268px;
  --_134tqwz79: 276px;
  --_134tqwz7a: 0px;
  --_134tqwz7b: 8px;
  --_134tqwz7c: 16px;
  --_134tqwz7d: 24px;
  --_134tqwz7e: 32px;
  --_134tqwz7f: 40px;
  --_134tqwz7g: 48px;
  --_134tqwz7h: 56px;
  --_134tqwz7i: 64px;
  --_134tqwz7j: 72px;
  --_134tqwz7k: 80px;
  --_134tqwz7l: 88px;
  --_134tqwz7m: 96px;
  --_134tqwz7n: 104px;
  --_134tqwz7o: 112px;
  --_134tqwz7p: 120px;
  --_134tqwz7q: 128px;
  --_134tqwz7r: 136px;
  --_134tqwz7s: 144px;
  --_134tqwz7t: 152px;
  --_134tqwz7u: 160px;
  --_134tqwz7v: 168px;
  --_134tqwz7w: 176px;
  --_134tqwz7x: 184px;
  --_134tqwz7y: 192px;
  --_134tqwz7z: 200px;
  --_134tqwz80: 208px;
  --_134tqwz81: 216px;
  --_134tqwz82: 224px;
  --_134tqwz83: 232px;
  --_134tqwz84: 240px;
  --_134tqwz85: 248px;
  --_134tqwz86: 256px;
  --_134tqwz87: 264px;
  --_134tqwz88: 272px;
  --_134tqwz89: 4px;
  --_134tqwz8a: 12px;
  --_134tqwz8b: 20px;
  --_134tqwz8c: 28px;
  --_134tqwz8d: 36px;
  --_134tqwz8e: 44px;
  --_134tqwz8f: 52px;
  --_134tqwz8g: 60px;
  --_134tqwz8h: 68px;
  --_134tqwz8i: 76px;
  --_134tqwz8j: 84px;
  --_134tqwz8k: 92px;
  --_134tqwz8l: 100px;
  --_134tqwz8m: 108px;
  --_134tqwz8n: 116px;
  --_134tqwz8o: 124px;
  --_134tqwz8p: 132px;
  --_134tqwz8q: 140px;
  --_134tqwz8r: 148px;
  --_134tqwz8s: 156px;
  --_134tqwz8t: 164px;
  --_134tqwz8u: 172px;
  --_134tqwz8v: 180px;
  --_134tqwz8w: 188px;
  --_134tqwz8x: 196px;
  --_134tqwz8y: 204px;
  --_134tqwz8z: 212px;
  --_134tqwz90: 220px;
  --_134tqwz91: 228px;
  --_134tqwz92: 236px;
  --_134tqwz93: 244px;
  --_134tqwz94: 252px;
  --_134tqwz95: 260px;
  --_134tqwz96: 268px;
  --_134tqwz97: 276px;
}
._17lrcdw0 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._17lrcdw1 {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: var(--_134tqwzt);
  border-bottom-left-radius: var(--_134tqwzt);
  border-color: var(--_134tqwz35);
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--_134tqwz6b);
}
._17lrcdw2 {
  width: 16px;
  height: 16px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
._17lrcdw3 {
  opacity: 0;
}
._17lrcdw4 {
  border-top-right-radius: var(--_134tqwzt);
  border-bottom-right-radius: var(--_134tqwzt);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: var(--_134tqwz35);
  border-left: none;
  line-height: var(--_134tqwzr);
  height: 42px;
}
._17lrcdw5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--_134tqwz5d);
  padding: var(--_134tqwz5d);
  border-bottom: 1px solid var(--_134tqwz35);
  border-radius: var(--_134tqwzt);
  background-color: transparent;
  color: var(--_134tqwz30);
  border: none;
  cursor: pointer;
  margin: 0 var(--_134tqwz6b);
}
._17lrcdw5:hover {
  background-color: var(--_134tqwz3q);
}
._17lrcdw6 {
  border: none;
  background-color: transparent;
  color: var(--_134tqwz30);
  width: 24px;
  height: -moz-fit-content;
  height: fit-content;
  margin-right: var(--_134tqwz6b);
}
._17lrcdw6 svg {
  width: 24px;
  border-radius: var(--_134tqwzt);
  margin-bottom: -2px;
}
._17lrcdw7 {
  width: 24px;
  height: 16px;
  border-radius: var(--_134tqwzt);
  background-color: var(--_134tqwz35);
}
._17lrcdw8 {
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow-y: auto;
  padding: var(--_134tqwz6b) 0;
}
._17lrcdw9 {
  color: var(--_134tqwz30);
  margin-left: auto;
}
._17lrcdwa {
  width: 16px;
  height: 16px;
  opacity: 0;
}
._17lrcdwb {
  opacity: 1;
}
._1x9t3ya0 {
  display: flex;
  flex-direction: column;
  gap: var(--_134tqwz5d);
}
._1x9t3ya1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._1x9t3ya2 {
  border-top-right-radius: var(--_134tqwzt);
  border-bottom-right-radius: var(--_134tqwzt);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: var(--_134tqwz35);
  border-left: none;
  line-height: var(--_134tqwzr);
  height: 42px;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_179fbf';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_179fbf';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_179fbf {font-family: '__Inter_179fbf', '__Inter_Fallback_179fbf', Roboto, sans-serif;font-style: normal
}.__variable_179fbf {--fonts-inter: '__Inter_179fbf', '__Inter_Fallback_179fbf', Roboto, sans-serif
}

